import React from "react";
import "./about.css";
import border_about from "../../../img/border_about.webp";
import about_1 from "../../../img/about_1.webp";
import about_2 from "../../../img/about_2.webp";
import about_3 from "../../../img/about_3.webp";
import iframe from "../../../img/iframe.webp";
import { Link } from "react-scroll";

const About = () => {
  return (
    <div id="about">
      <div className="about">
        <div className="title">
          <img
            src={border_about}
            alt="border_about"
            className="border_about_1"
          />
          <p>معلومات عنا</p>
          <img
            src={border_about}
            alt="border_about"
            className="border_about_2"
          />
        </div>
        <p className="p_1">
          لافندر ايفينتس شركة قطرية متخصصة في تنظيم الفعاليات و الاحتفالات
          والمؤتمرات وتصميمات الزهور الفريدة والأنيقة، نحن نقدم خدماتنا المتميزة
          لتلبية جميع احتياجات عملائنا في تنظيم وتنفيذ الأحداث الخاصة بهم{" "}
        </p>

        <div className="about_images">
          <div className="left">
            <img src={about_1} alt="about_1" />
          </div>
          <div className="right">
            <img src={about_2} alt="about_2" />
            <img src={about_3} alt="about_3" />
          </div>
        </div>

        <div className="youtube">
          <img className="iframe" src={iframe} alt="iframe" />

          <div className="right">
            <p>
              سواء كنتم تخططون لحفل زفاف راقٍ، أو مؤتمر عالمي، أو احتفال اجتماعي
              راقٍ، فإننا نهتم بأدق التفاصيل لضمان تنظيم حدث لا يُنسى.
            </p>

            <Link
              className="get"
              to="contact"
              spy={true}
              smooth={true}
              offset={-150}
              duration={500}
            >
              ابقى على تواصل
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
