import React from "react";
import "./footer.css";
import { Link } from "react-scroll";
import {
  FaFacebook,
  FaWhatsapp,
  FaTelegram,
  FaTiktok,
  FaInstagram,
} from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";
import { MdArrowBackIos } from "react-icons/md";

import image_footer from "../../img/image_footer.webp";
import Copyrights from "../copyrights/Copyrights";
import logo from "../../img/logo.webp";

const Footer = () => {
  const links = [
    { to: "header", label: "الرئيسية" },
    { to: "about", label: "معلومات عنا" },
    { to: "galley", label: "أعمالنا" },
    { to: "services", label: "خدماتنا" },
    { to: "contact", label: "تواصل معنا" },
  ];

  const social_media = [
    // { href: "#", name: <FaFacebook /> },
    {
      href: "https://api.whatsapp.com/send?phone=97455245454",
      name: <FaWhatsapp />,
    },
    // { href: "#", name: <AiFillTwitterCircle /> },
    // { href: "#", name: <FaTelegram /> },
    // { href: "#", name: <FaTiktok /> },
    {
      href: "https://www.instagram.com/lavender__qa?igsh=ZnlpaDcxcWN1aHBt",
      name: <FaInstagram />,
    },
  ];

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div id="footer">
      <div className="MdArrowBackIos" onClick={handleTop}>
        <MdArrowBackIos />
      </div>
      <div className="footer">
        <div className="image_footer">
          <img src={image_footer} alt="image_footer" />
        </div>

        <div className="right">
          <Link
            to="header"
            className="logo"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            <img src={logo} alt="logo" />{" "}
          </Link>

          <ul className="links">
            {links.map((item, i) => (
              <li key={i}>
                <Link
                  to={item.to}
                  spy={true}
                  smooth={true}
                  offset={-150}
                  duration={500}
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>

          <div className="social_media">
            {social_media.map((item, i) => (
              <a href={item.href} target="_blank" key={i}>
                {item.name}
              </a>
            ))}
          </div>

          <Copyrights />
        </div>
      </div>
    </div>
  );
};

export default Footer;
