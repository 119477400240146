import React from "react";
import "./header.css";
import img_header from "../../../img/header.webp";
import { Link } from "react-scroll";

const Header = () => {
  return (
    <div id="header">
      <img src={img_header} alt="img_header" className="img_header" />

      <div className="header">
        <p className="p_1">
          <span>مرحبًا بكم في</span>
          لافندر ايفينتس
        </p>

        <p className="p_2">
          نقدم تجارب فريدة ومبتكرة تلبي تمامًا احتياجاتكم وتفوق توقعاتكم في
          تنظيم الاحتفالات ، الأعراس ،الفعاليات ، الأحداث الخاصة.
        </p>

        <Link
          className="button"
          to="services"
          spy={true}
          smooth={true}
          offset={-150}
          duration={500}
        >
          خدماتنا
        </Link>
      </div>
    </div>
  );
};

export default Header;
