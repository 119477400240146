import React from "react";
import border_about from "../../../img/border_about.webp";
import "./galley.css";
import slider_galley_1 from "../../../img/slider_galley_1.webp";
import slider_galley_2 from "../../../img/slider_galley_2.webp";
import slider_galley_3 from "../../../img/slider_galley_1.webp";
import slider_galley_4 from "../../../img/slider_galley_2.webp";
import Slider from "./Slider";

const Galley = () => {
  const list_galley = [
    { img: slider_galley_1 },
    { img: slider_galley_2 },
    { img: slider_galley_3 },
    { img: slider_galley_4 },
  ];

  return (
    <div id="galley">
      <div className="galley">
        <div className="title">
          <img
            src={border_about}
            alt="border_about"
            className="border_about_1"
          />
          <p className="p_border_about_1">إبداعاتنا الخاصة</p>
          <p>أعمالنا</p>
          <img
            src={border_about}
            alt="border_about"
            className="border_about_2"
          />
        </div>
        <p className="p_1">
          نقدم لكم مجموعة من الصور لتستمتعوا بنظرة مفصلة عن بعض من أعمالنا
          السابقة التي تعكس إبداعنا وخبرتنا في تنظيم الحفلات، الأعراس والأحداث.
          <br />
          كل تفصيله يتم اختيارها بعناية لخلق أجواء مميزة. <br />
          نضمن لكم تجربة فريدة ومخصصة تمامًا لتلائم احتياجاتكم وتفضيلاتكم.
        </p>

        <div className="slider">
          <Slider list={list_galley} />
        </div>
      </div>
    </div>
  );
};

export default Galley;
