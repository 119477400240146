import React from "react";
import border_contact from "../../../img/border_about.webp";
import img_contact from "../../../img/img_contact.webp";
import "./contact.css";
import { useForm, Controller } from "react-hook-form";
import { FaCheck } from "react-icons/fa6";

const Contact = () => {
  const { handleSubmit, control, reset } = useForm();

  function sendWhatsAppMessage(data) {
    let message = "";
    Object.keys(data).forEach((key) => {
      message += `${key}: ${data[key]}\n`;
    });

    window.open(
      `https://api.whatsapp.com/send?phone=97455245454&text=${encodeURIComponent(
        message
      )}`,
      "_blank"
    );
  }

  const onSubmit = (data) => {
    sendWhatsAppMessage(data);

    reset();
  };

  return (
    <div id="contact">
      <img src={img_contact} alt="img_contact" className="img_contact" />
      <div className="contact">
        <div className="title">
          <img
            src={border_contact}
            alt="border_contact"
            className="border_contact_1"
          />
          <p>تواصل معنا</p>
          <img
            src={border_contact}
            alt="border_contact"
            className="border_contact_2"
          />
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="form_contact">
          <div className="firstName_lastName">
            <div>
              <label>الاسم الأول</label>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input type="text" required {...field} placeholder="|" />
                )}
              />
            </div>

            <div>
              <label>اسم العائلة</label>
              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="text"
                    required
                    {...field}
                    placeholder="اسم العائلة"
                  />
                )}
              />
            </div>
          </div>
          <div className="firstName_lastName">
            <div>
              <label>بريد إلكتروني</label>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input type="email" required {...field} />
                )}
              />
            </div>

            <div>
              <label>رقم الهاتف</label>
              <Controller
                name="phoneNumber"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <input
                    type="number"
                    required
                    {...field}
                    placeholder="+974 7010 0403"
                  />
                )}
              />
            </div>
          </div>
          <div className="select_subject">
            <label>حدد الموضوع؟</label>
            <Controller
              name="subject"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="select_subject_input">
                  <div>
                    <input
                      type="radio"
                      required
                      {...field}
                      value="عام"
                      id="radio_1"
                    />
                    <span>
                      <FaCheck />
                    </span>
                    <label htmlFor="radio_1">عام</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      {...field}
                      value="تصميم الزهور"
                      id="radio_2"
                    />
                    <span>
                      <FaCheck />
                    </span>
                    <label htmlFor="radio_2">تصميم الزهور</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      {...field}
                      value="الشوكولاته الفاخرة"
                      id="radio_3"
                    />
                    <span>
                      <FaCheck />
                    </span>
                    <label htmlFor="radio_3">الشوكولاته الفاخرة</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      {...field}
                      value="الضيافة الراقية"
                      id="radio_4"
                    />
                    <span>
                      <FaCheck />
                    </span>
                    <label htmlFor="radio_4">الضيافة الراقية</label>
                  </div>
                </div>
              )}
            />
          </div>
          <div className="message">
            <label>الرسالة</label>
            <Controller
              name="message"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  type="text"
                  required
                  {...field}
                  placeholder="اكتب رسالتك..."
                />
              )}
            />
          </div>

          <div className="submit_button">
            <button type="submit">ارسال</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
