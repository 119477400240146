import React, { useState } from "react";

const Slider = ({ list }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (image) => {
    setCurrentIndex(image);
  };

  return (
    <>
      <div className="selected">
        <img src={list[currentIndex].img} alt="Selected" />
      </div>

      <div className="list">
        {list.map((item, index) => (
          <img
            key={index}
            src={item.img}
            alt={`Image ${index}`}
            onClick={() => handleImageClick(index)}
            className={currentIndex === index ? "active" : ""}
          />
        ))}
      </div>
    </>
  );
};

export default Slider;
