import React from "react";
import Header from "./header/Header";
import About from "./about/About";
import Galley from "./galley/Galley";
import Services from "./services/Services";
import Contact from "./contact/Contact";

const Home = () => {
  return (
    <>
      <Header />
      <About />
      <Galley />
      <Services />
      <Contact />
    </>
  );
};

export default Home;
