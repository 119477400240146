import React from "react";
import border_services from "../../../img/border_about.webp";
import img_services from "../../../img/img_services.webp";
import img_services_data_1 from "../../../img/img_services_data_1.webp";
import img_services_data_2 from "../../../img/img_services_data_2.webp";
import img_services_data_3 from "../../../img/img_services_data_3.webp";
import "./services.css";
import { Link } from "react-scroll";

const Services = () => {
  return (
    <>
      <div id="services">
        <img src={img_services} alt="img_services" className="img_services" />
        <div className="services">
          <div className="title">
            <img
              src={border_services}
              alt="border_services"
              className="border_services_1"
            />
            <p>خدماتنا</p>
            <img
              src={border_services}
              alt="border_services"
              className="border_services_2"
            />
          </div>
          <p className="p_1">
            نقدم مجموعة متنوعة ومتميزة من الخدمات ، حيث أننا نسعى جاهدين لتقديم
            تجربة استثنائية لكل حدث خاص ،مع الإهتمام بأدق التفاصيل.
          </p>
        </div>
      </div>
      <div className="services_data">
        <div className="data">
          <div className="div_data">
            <div className="img">
              <img src={img_services_data_1} alt="img_services_data_1" />
            </div>
            <p className="p_1">تصميم الزهور</p>
            <p className="p_2">
              نحرص على استخدام أجود أنواع الزهور وترتيبها بأسلوب فني رائع لإضفاء
              جمال ورونق على الحدث.
            </p>
          </div>
          <div className="div_data">
            <div className="img">
              <img src={img_services_data_2} alt="img_services_data_2" />
            </div>
            <p className="p_1">الشوكولاته الفاخرة</p>
            <p className="p_2">
              نقدم شوكولاته فاخرة بطعم لا يُقاوم، مع اهتمامنا بالتفاصيل واختيار
              المكونات الفاخرة لتوفير تجربة طعام استثنائية لضيوفك.
            </p>
          </div>
          <div className="div_data">
            <div className="img">
              <img src={img_services_data_3} alt="img_services_data_3" />
            </div>
            <p className="p_1">الضيافة الراقية</p>
            <p className="p_2">
              نحرص على توفير ضيافة راقية ومتميزة، مع تركيزنا على الخدمة الشخصية
              والاهتمام بالتفاصيل.
            </p>
          </div>
        </div>

        <div className="button_a">
          <a
            className="button"
            href="https://api.whatsapp.com/send?phone=97455245454"
            target="_blank"
          >
            احصل على عرضك الآن
          </a>

          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-150}
            duration={500}
          >
            ابقى على تواصل
          </Link>
        </div>
      </div>
    </>
  );
};

export default Services;
