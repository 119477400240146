import React, { useState } from "react";
import "./navbar.css";
import logo from "../../img/logo.webp";
import image_navbar from "../../img/image_navbar.webp";
import { NavLink, Outlet } from "react-router-dom";
import Footer from "./../footer/Footer";
import { Link } from "react-scroll";

const Navbar = () => {
  const [isChecked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!isChecked);
  };

  const links = [
    { to: "header", label: "الرئيسية" },
    { to: "about", label: "معلومات عنا" },
    { to: "galley", label: "أعمالنا" },
    { to: "services", label: "خدماتنا" },
    { to: "contact", label: "تواصل معنا" },
  ];

  return (
    <>
      <nav>
        <input
          type="checkbox"
          id="nav-toggle"
          checked={isChecked}
          onChange={handleToggle}
        />

        <div className="image_navbar">
          <img src={image_navbar} alt="logo" />
        </div>

        <ul className="links" onClick={handleToggle}>
          {links.map((item, i) => (
            <li key={i}>
              <Link
                onClick={handleToggle}
                to={item.to}
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
              >
                {item.label}
              </Link>
            </li>
          ))}
        </ul>

        <NavLink to="/" className="logo">
          <img src={logo} alt="logo" />
        </NavLink>

        <label htmlFor="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>

      <Outlet />

      <Footer />
    </>
  );
};

export default Navbar;
